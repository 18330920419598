'use strict';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { error } from "laravel-mix/src/Log";
gsap.registerPlugin(ScrollTrigger);

const mediaQuery = window.matchMedia("(max-width: 1000px)");
var Slider = document.querySelector(".website_container__slider")
var Websites = document.querySelectorAll(".website_container__slider__website");
var Description = document.querySelector(".phone_card__main-content__text")
var MainContentPhoneCard = document.querySelector(".phone_card__main-content")
var PcContainer = document.querySelector(".height-adjuster")
var PhoneCard = document.querySelector(".phone_card");
var FooterCard = document.createElement("div")
FooterCard.classList.add("phone_card__footer")
var LevelSection = document.querySelector(".phone_card__header__right")
var CardHeader = document.querySelector(".phone_card__header")
const SkillButton = document.querySelector(".skill_sheet__button");
const ButtonContainer = document.createElement("div")
ButtonContainer.classList.add('phone_card__footer__button-container');
let Main = document.querySelector("main")
let DescriptionContentChecker = "<p>Yo, je m'appelle Dorian&nbsp;!</p> <p>J'ai hâte de vous présenter tous mes différents projets&nbsp;!</p>";
const StandingImage = document.querySelector(".standing")
const BlinkingImage = document.querySelector(".blinking")
const FirstSpeakingImage =  document.querySelector(".speaking-1")
const SecondSpeakingImage =  document.querySelector(".speaking-2")
let Level =  document.querySelector(".level");
const SkillSheet = document.querySelector(".skill_sheet");
let TurnButton = document.createElement("button")
TurnButton.classList.add("skill_sheet__list__flip-button")
const SkillList = document.querySelector(".skill_sheet__list");
TurnButton.innerHTML = "Retourner la carte";
const Francais = document.getElementById("français");
const English = document.getElementById("english");
let Body = document.querySelector("body");

/* POP UP */

let ClosePopUP = document.querySelector('.close-popup');
let PopUp = document.querySelector(".popup")
let Close = document.querySelector(".close")
var StorageValue = sessionStorage.getItem("pop");
if(StorageValue === "seen") {
    PopUp.classList.add("remove");
    Body.classList.remove("scrollblocked")
    sessionStorage.setItem("pop", "seen")
}

ClosePopUP.addEventListener("click", () => {
    sessionStorage.setItem("pop", "unseen")
    PopUp.classList.add("remove");
    Body.classList.remove("scrollblocked")
    sessionStorage.setItem("pop", "seen")
    StorageValue = sessionStorage.getItem("pop");
})
Close.addEventListener("click", () => {
    sessionStorage.setItem("pop", "unseen")
    PopUp.classList.add("remove");
    Body.classList.remove("scrollblocked")
    sessionStorage.setItem("pop", "seen")
    StorageValue = sessionStorage.getItem("pop");
})
/* POP UP */

function phoneversion() {
    document.querySelector(".phone_card").classList.remove("none")
    /* document.querySelector(".pc_card").classList.add("none"); */
    document.querySelector(".skill_sheet").classList.remove("none");

fetch('../datas/data.json')
.then(response => {
    if(!response.ok) {
        throw new Error('La requête a échoué');
    }
    return response.json();
})
.then(data => {
    datajson(data)
})
.catch(error => {
    console.error('Erreur lors de la récupération des fichiers JSON', error);
});
let Header = document.querySelector("header");
let Minus = document.querySelector(".minus");
let Avatar = document.querySelector(".avatar");
const ProgressionBar = document.querySelector(".progression-bar");
let mm = gsap.matchMedia();
let FaceAnimationPhone = [
    "assets/images/avatar_phone_blinking.png",
    "assets/images/avatar_phone_talking.png",
    "assets/images/avatar_phone.png",
    "assets/images/avatar_phone_talking_between.png"
];


function datajson(jsonDatas) {

    window.addEventListener("scroll", ()=> {

        if(window.scrollY >= ScrollValue) {
            Header.style.transform = "translate(0, -95px)";
            ScrollValue = window.scrollY
        } else {
            Header.style.transform = "translate(0, 0px)";
            ScrollValue = window.scrollY
        }

        for(let i = 0; i < Websites.length; i++) {
                    if((Websites[i].offsetTop - window.scrollY) <= window.innerHeight/2) {
                        
                        if(jsonDatas[jsonDatas.Projects[i]].length > 1) {
                            Description.innerHTML = `<p>${jsonDatas[jsonDatas.Projects[i]][0]}</p><p>${jsonDatas[jsonDatas.Projects[i]][1]}</p>`
                        } else {
                            Description.innerHTML = `<p>${jsonDatas[jsonDatas.Projects[i]][0]}</p>`
                        }
                    } else if ((Websites[0].offsetTop - window.scrollY) > window.innerHeight/2) {
                        Description.innerHTML = "<p>Yo, je m'appelle Dorian&nbsp;!</p> <p>J'ai hâte de vous présenter tous mes différents projets&nbsp;!</p>" 
                    }
        }
        if(DescriptionContentChecker !== Description.innerHTML) {
            DescriptionContentChecker = Description.innerHTML
            StandingImage.classList.remove("notalking")
            StandingImage.classList.add("talking")
            BlinkingImage.classList.add("unactive")
            FirstSpeakingImage.classList.remove("unactive")
            SecondSpeakingImage.classList.remove("unactive")
            setTimeout(()=> {
                FirstSpeakingImage.classList.add("unactive")
                SecondSpeakingImage.classList.add("unactive")
                BlinkingImage.classList.remove("unactive")
                StandingImage.classList.add("notalking")
                StandingImage.classList.remove("talking")
            }, 5000)
          /*  clearInterval(BlinkingInterval)
            SpeakingInterval = setInterval(talking, 300)
            setTimeout(() => {
                clearInterval(SpeakingInterval)
                BlinkingInterval = setInterval(blinking, 5000);
                Speaking = false
                DescriptionContentChecker = Description.innerHTML //Pour éviter qu'il se remette à parler une deuxième fois si on fait un aller/retour
            }, 5000); */
        }
        /*
        Code pas ouf
        for(let i = 0; i < Websites.length; i++) {
            if (i !== Websites.length-1) {
                if((Websites[i].offsetTop - window.scrollY) <= window.innerHeight/2) {
                    if((Websites[i+1].offsetTop - window.scrollY) >= window.innerHeight/2) {
                    Description.innerHTML ="<p>" +  jsonDatas.Descriptions.Projet + "</p>"
                    }
                }   
            } else { // J'évite de prendre le dernier élément du tableau car il n'y a pas d'élément qui suit
                if((Websites[i].offsetTop - window.scrollY) <= window.innerHeight/2) {
                    console.log("Tu as passé le dernier projet")
                }
            }
        } */
    
    })
}
let FillingBar = document.querySelector(".filling");
let ScrollValue = 0

Minus.addEventListener("click", ()=> {
    PhoneCard.classList.toggle("hidden");
    if(PhoneCard.classList.contains("hidden")) {
        PhoneCard.style.transform = `translate(0, ${Description.offsetHeight}px)`
        Minus.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M24 10H14V0h-4v10H0v4h10v10h4V14h10z"/></svg>';
    /*    clearInterval(BlinkingInterval); */
    } else { 
        PhoneCard.style.transform = `translate(0, 0)`
        Minus.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M0 10h24v4H0z"/></svg>';
    /*    BlinkingInterval = setInterval(blinking, 5000); */
    }
})
/*
function blinking() {
        Avatar.setAttribute("src", FaceAnimationPhone[0])
    setTimeout(()=> {
        Avatar.setAttribute("src", FaceAnimationPhone[2])
    }, 250);
}

function talking() {
    Avatar.setAttribute("src", FaceAnimationPhone[1])
setTimeout(()=> {
    Avatar.setAttribute("src", FaceAnimationPhone[3])
}, 200);
setTimeout(()=> {
    Avatar.setAttribute("src", FaceAnimationPhone[2])
}, 400);
}
let BlinkingInterval = setInterval(blinking, 5000); */
if(SkillSheet.classList.contains("hidden")) {
    SkillSheet.style.transform = `translate(0, ${SkillList.offsetHeight}px)`;
}
SkillButton.addEventListener("click", ()=> {
    SkillSheet.classList.toggle("hidden");
    if(SkillSheet.classList.contains("hidden")) {
        SkillSheet.style.transform = `translate(0, ${SkillList.offsetHeight}px)`;
        SkillButton.innerHTML = "+ Voir les compétences";
        Body.classList.remove("scrollblocked")
    }
    else {
        SkillSheet.style.transform = `translate(0, 0)`;
        SkillButton.innerHTML = "X Fermer";
        console.log("yo")
        Body.classList.add("scrollblocked")
    }
})

mm.add("(max-width: 1000px)", () => {
    for(let i=0; i < Websites.length; i++) {
        if(i%2 == 0) {
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: Websites[i],
                    scrub: true,
                    start: "top center",
                    end: "bottom center",
                    /* markers: true, */
                    duration: 2
                    }
            })
    
            tl.from(".filling", {scaleX:0})
            .to(".filling", {scaleX:1})
        } else {
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: Websites[i],
                    scrub: true,
                    start: "top center",
                    end: "bottom center",
                    /* markers: true, */
                    duration: 2,
                    onEnterBack: ()=>{
                        if (Websites[i].classList.contains("completed")) {
                            Websites[i].classList.remove("completed");
                            Level.innerHTML = Number(Level.innerHTML)-1;
                        }
                    },
                    onLeave: ()=>{
                        if (Websites[i].classList.contains("completed") == false) {
                            Websites[i].classList.add("completed");
                            Level.innerHTML = Number(Level.innerHTML)+1;
        
                        }
                    }
                    }
            });
            tl.from(".filling2", {scaleX:0,})
            .to(".filling2", {scaleX:1})
            .to(".filling2", {scaleX:0,duration: 0})
            .to(".filling", {scaleX:0,duration:0
            }, "<")
        }
    }
    
}) 
}

if (mediaQuery.matches) {
        document.querySelector(".phone_card").classList.remove("none")
        /* document.querySelector(".pc_card").classList.add("none"); */
        document.querySelector(".skill_sheet").classList.remove("none");

    fetch('../datas/data.json')
    .then(response => {
        if(!response.ok) {
            throw new Error('La requête a échoué');
        }
        return response.json();
    })
    .then(data => {
        datajson(data)
    })
    .catch(error => {
        console.error('Erreur lors de la récupération des fichiers JSON', error);
    });
    let Body = document.querySelector("body");
    let Header = document.querySelector("header");
    let Minus = document.querySelector(".minus");
    let Avatar = document.querySelector(".avatar");
    const ProgressionBar = document.querySelector(".progression-bar");
    let FaceAnimationPhone = [
        "assets/images/avatar_phone_blinking.png",
        "assets/images/avatar_phone_talking.png",
        "assets/images/avatar_phone.png",
        "assets/images/avatar_phone_talking_between.png"
    ];


    function datajson(jsonDatas) {

        window.addEventListener("scroll", ()=> {

            if(window.scrollY >= ScrollValue) {
                Header.style.transform = "translate(0, -95px)";
                ScrollValue = window.scrollY
            } else {
                Header.style.transform = "translate(0, 0px)";
                ScrollValue = window.scrollY
            }

            for(let i = 0; i < Websites.length; i++) {
                        if((Websites[i].offsetTop - window.scrollY) <= window.innerHeight/2) {
                            
                            if(jsonDatas[jsonDatas.Projects[i]].length > 1) {
                                Description.innerHTML = `<p>${jsonDatas[jsonDatas.Projects[i]][0]}</p><p>${jsonDatas[jsonDatas.Projects[i]][1]}</p>`
                            } else {
                                Description.innerHTML = `<p>${jsonDatas[jsonDatas.Projects[i]][0]}</p>`
                            }
                        } else if ((Websites[0].offsetTop - window.scrollY) > window.innerHeight/2) {
                            Description.innerHTML = "<p>Yo, je m'appelle Dorian&nbsp;!</p> <p>J'ai hâte de vous présenter tous mes différents projets&nbsp;!</p>" 
                        }
            }
            if(DescriptionContentChecker !== Description.innerHTML) {
                DescriptionContentChecker = Description.innerHTML
                StandingImage.classList.remove("notalking")
                StandingImage.classList.add("talking")
                BlinkingImage.classList.add("unactive")
                FirstSpeakingImage.classList.remove("unactive")
                SecondSpeakingImage.classList.remove("unactive")
                setTimeout(()=> {
                    FirstSpeakingImage.classList.add("unactive")
                    SecondSpeakingImage.classList.add("unactive")
                    BlinkingImage.classList.remove("unactive")
                    StandingImage.classList.add("notalking")
                    StandingImage.classList.remove("talking")
                }, 5000)
              /*  clearInterval(BlinkingInterval)
                SpeakingInterval = setInterval(talking, 300)
                setTimeout(() => {
                    clearInterval(SpeakingInterval)
                    BlinkingInterval = setInterval(blinking, 5000);
                    Speaking = false
                    DescriptionContentChecker = Description.innerHTML //Pour éviter qu'il se remette à parler une deuxième fois si on fait un aller/retour
                }, 5000); */
            }
            /*
            Code pas ouf
            for(let i = 0; i < Websites.length; i++) {
                if (i !== Websites.length-1) {
                    if((Websites[i].offsetTop - window.scrollY) <= window.innerHeight/2) {
                        if((Websites[i+1].offsetTop - window.scrollY) >= window.innerHeight/2) {
                        Description.innerHTML ="<p>" +  jsonDatas.Descriptions.Projet + "</p>"
                        }
                    }   
                } else { // J'évite de prendre le dernier élément du tableau car il n'y a pas d'élément qui suit
                    if((Websites[i].offsetTop - window.scrollY) <= window.innerHeight/2) {
                        console.log("Tu as passé le dernier projet")
                    }
                }
            } */
        
        })
    }
    let FillingBar = document.querySelector(".filling");
    let ScrollValue = 0

    Minus.addEventListener("click", ()=> {
        PhoneCard.classList.toggle("hidden");
        if(PhoneCard.classList.contains("hidden")) {
            PhoneCard.style.transform = `translate(0, ${Description.offsetHeight}px)`
            Minus.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M24 10H14V0h-4v10H0v4h10v10h4V14h10z"/></svg>';
        /*    clearInterval(BlinkingInterval); */
        } else { 
            PhoneCard.style.transform = `translate(0, 0)`
            Minus.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M0 10h24v4H0z"/></svg>';
        /*    BlinkingInterval = setInterval(blinking, 5000); */
        }
    })
    /*
    function blinking() {
            Avatar.setAttribute("src", FaceAnimationPhone[0])
        setTimeout(()=> {
            Avatar.setAttribute("src", FaceAnimationPhone[2])
        }, 250);
    }

    function talking() {
        Avatar.setAttribute("src", FaceAnimationPhone[1])
    setTimeout(()=> {
        Avatar.setAttribute("src", FaceAnimationPhone[3])
    }, 200);
    setTimeout(()=> {
        Avatar.setAttribute("src", FaceAnimationPhone[2])
    }, 400);
    }
    let BlinkingInterval = setInterval(blinking, 5000); */
    if(SkillSheet.classList.contains("hidden")) {
        SkillSheet.style.transform = `translate(0, ${SkillList.offsetHeight}px)`;
    }
    SkillButton.addEventListener("click", ()=> {
        SkillSheet.classList.toggle("hidden");
        if(SkillSheet.classList.contains("hidden")) {
            SkillSheet.style.transform = `translate(0, ${SkillList.offsetHeight}px)`;
            SkillButton.innerHTML = "+ Voir les compétences";
            Body.classList.remove("scrollblocked")
        }
        else {
            SkillSheet.style.transform = `translate(0, 0)`;
            SkillButton.innerHTML = "X Fermer";
            console.log("yo")
            Body.classList.add("scrollblocked")
        }
    })

    
} else {

    PcContainer.appendChild(Description)
    PcContainer.appendChild(FooterCard)
    FooterCard.appendChild(LevelSection)
    FooterCard.appendChild(ButtonContainer)
    ButtonContainer.appendChild(SkillButton)
    PhoneCard.appendChild(SkillSheet)
    SkillList.appendChild(TurnButton)
    SkillSheet.style.height = FooterCard.offsetHeight + PhoneCard.offsetHeight + "px"; 
     window.addEventListener("resize", ()=> {
        SkillSheet.style.height = FooterCard.offsetHeight + PhoneCard.offsetHeight + "px"; 
     })
     /* SkillSheet.style.height = FooterCard.offsetHeight + PhoneCard.offsetHeight + "px"; */
    document.querySelector(".full-word").innerHTML = "Niveau";
    SkillButton.innerHTML = "Voir les compétences"
    Francais.innerHTML = "Français";
    English.innerHTML = "English"
    SkillButton.addEventListener("click", ()=> {
        PhoneCard.classList.add("rotate")
        FooterCard.classList.add("rotate")
        setTimeout(()=>{
            SkillSheet.classList.remove('behind')
            SkillSheet.classList.add('flipped')
            FooterCard.classList.add("back")
        }, 500)
        setTimeout(()=>{
            PhoneCard.classList.remove("rotate")
            FooterCard.classList.remove("rotate")
            SkillSheet.classList.add("flipped-back")
        }, 1000)
    })
    TurnButton.addEventListener("click", ()=> {
        PhoneCard.classList.add("rotate-back")
        FooterCard.classList.add("rotate-back")
        setTimeout(()=>{
            SkillSheet.classList.add('behind')
            SkillSheet.classList.remove('flipped')
            FooterCard.classList.remove("back")
        }, 500)
        setTimeout(()=>{
            PhoneCard.classList.remove("rotate-back")
            FooterCard.classList.remove("rotate-back")
            SkillSheet.classList.remove("flipped-back")
        }, 1000)
    })
    
    
}

let mm = gsap.matchMedia();
mm.add("(max-width: 1000px)", () => {
    for(let i=0; i < Websites.length; i++) {
        if(i%2 == 0) {
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: Websites[i],
                    scrub: true,
                    start: "top center",
                    end: "bottom center",
                    /* markers: true, */
                    duration: 2
                    }
            })
    
            tl.from(".filling", {scaleX:0})
            .to(".filling", {scaleX:1})
        } else {
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: Websites[i],
                    scrub: true,
                    start: "top center",
                    end: "bottom center",
                    /* markers: true, */
                    duration: 2,
                    onEnterBack: ()=>{
                        if (Websites[i].classList.contains("completed")) {
                            Websites[i].classList.remove("completed");
                            Level.innerHTML = Number(Level.innerHTML)-1;
                        }
                    },
                    onLeave: ()=>{
                        if (Websites[i].classList.contains("completed") == false) {
                            Websites[i].classList.add("completed");
                            Level.innerHTML = Number(Level.innerHTML)+1;
        
                        }
                    }
                    }
            });
            tl.from(".filling2", {scaleX:0,})
            .to(".filling2", {scaleX:1})
            .to(".filling2", {scaleX:0,duration: 0})
            .to(".filling", {scaleX:0,duration:0
            }, "<")
        }
    }
    
}) 

let pp = gsap.matchMedia();
    pp.add("(min-width:1000px)", ()=> {
    var scrollAnimation = gsap.timeline({
        scrollTrigger: {
            trigger: Main,
            scrub: 1,
            end: "+=" + Slider.offsetHeight,
            /* markers: true, */
            pin: true,


            }
    })

    function labelToLevelUp(timeline, label) {
        let st = timeline.scrollTrigger;
        
        let val = (
          st.start +
          (Slider.offsetHeight - st.start) * (timeline.labels[label] / timeline.duration())
        )
        return val ;
      }
  
        

      for (let i = 0; i < Websites.length ; i++) {
        scrollAnimation.to(".website_container__slider", {yPercent:  ((-(100/(Websites.length+1))) * (i+1))})

        if(i%2 == 0) {
            scrollAnimation.from(".filling", {scaleX: 0}, "<")
            .to(".filling", {scaleX:1})
        } else {
            scrollAnimation.from(".filling2", {scaleX: 0}, "<")
            .to(".filling2", {scaleX:1})
            .to(".filling2", {scaleX:0,duration: 0})
            .to(".filling",  {scaleX:0,duration:0,
                onReverseComplete: ()=>{
                        if (Websites[i].classList.contains("completed")) {
                            Websites[i].classList.remove("completed");
                            Level.innerHTML = Number(Level.innerHTML)-1;
                        }
                },
                onComplete: ()=>{
                        if (Websites[i].classList.contains("completed") == false) {
                            Websites[i].classList.add("completed");
                            Level.innerHTML = Number(Level.innerHTML)+1;
                        }
                } }, "<")
            
            var LevelUp = `Level${i}`
            scrollAnimation.addLabel(LevelUp)
        }
        var LabelName = `Description${i}`
        scrollAnimation.addLabel(LabelName)

        
    }

    fetch('../datas/data.json')
    .then(response => {
        if(!response.ok) {
            throw new Error('La requête a échoué');
        }
        return response.json();
    })
    .then(data => {
        datajson(data)
    })
    .catch(error => {
        console.error('Erreur lors de la récupération des fichiers JSON', error);
    });

    if (window.scrollY < (labelToLevelUp(scrollAnimation, `Description${0}`)- (Websites[0].offsetHeight))) {
        StandingImage.classList.remove("notalking")
        StandingImage.classList.add("talking")
        BlinkingImage.classList.add("unactive")
        FirstSpeakingImage.classList.remove("unactive")
        SecondSpeakingImage.classList.remove("unactive")
        setTimeout(()=> {
            FirstSpeakingImage.classList.add("unactive")
            SecondSpeakingImage.classList.add("unactive")
            BlinkingImage.classList.remove("unactive")
            StandingImage.classList.add("notalking")
            StandingImage.classList.remove("talking")
        }, 5000)
    }

    function datajson(jsonDatas) {
        window.addEventListener("scroll", () => {
            for (let i = 0; i < Websites.length ; i++) {
                if(window.scrollY > (labelToLevelUp(scrollAnimation, `Description${i}`)- (Websites[i].offsetHeight))) {
                    if( i !== Websites.length-1 && window.scrollY  < labelToLevelUp(scrollAnimation, `Description${i+1}`)) {
                        if(jsonDatas[jsonDatas.Projects[i]].length > 1) {
                            Description.innerHTML = `<p>${jsonDatas[jsonDatas.Projects[i]][0]}</p><p>${jsonDatas[jsonDatas.Projects[i]][1]}</p>`
                        } else {
                            Description.innerHTML = `<p>${jsonDatas[jsonDatas.Projects[i]][0]}</p>`
                        }
                    } else if( i == Websites.length-1) {
                        if(jsonDatas[jsonDatas.Projects[i]].length > 1) {
                            Description.innerHTML = `<p>${jsonDatas[jsonDatas.Projects[Websites.length-1]][0]}</p><p>${jsonDatas[jsonDatas.Projects[Websites.length-1]][1]}</p>`
                        } else {
                            Description.innerHTML = `<p>${jsonDatas[jsonDatas.Projects[Websites.length-1]][0]}</p>`
                        }
                    } 
                }else if(window.scrollY < (labelToLevelUp(scrollAnimation, `Description${0}`) - (Websites[0].offsetHeight))) {
                    Description.innerHTML = "<p>Yo, je m'appelle Dorian&nbsp;!</p> <p>J'ai hâte de vous présenter tous mes différents projets&nbsp;!</p>" 
    
                }

            }
        
            if(DescriptionContentChecker !== Description.innerHTML) {
                DescriptionContentChecker = Description.innerHTML
                StandingImage.classList.remove("notalking")
                StandingImage.classList.add("talking")
                BlinkingImage.classList.add("unactive")
                FirstSpeakingImage.classList.remove("unactive")
                SecondSpeakingImage.classList.remove("unactive")
                setTimeout(()=> {
                    FirstSpeakingImage.classList.add("unactive")
                    SecondSpeakingImage.classList.add("unactive")
                    BlinkingImage.classList.remove("unactive")
                    StandingImage.classList.add("notalking")
                    StandingImage.classList.remove("talking")
                }, 5000)
            }
        
        
        })
            

        
        }
        
    })

mediaQuery.addEventListener('change', (event)=> {
    if(event.matches) {
        if(Francais.innerHTML == "Français") {
            MainContentPhoneCard.appendChild(Description)
            CardHeader.appendChild(LevelSection)
            PcContainer.removeChild(FooterCard)
            SkillList.prepend(SkillButton)
            PcContainer.appendChild(SkillSheet)
            SkillList.removeChild(TurnButton)
            SkillSheet.style.height = "unset"
            phoneversion()
        }

    } else {
       /* console.log("PC") */
    }
}) 
/*
mediaQuery.addListener((mediaQueryList) => {
    if (mediaQueryList.matches) {

    } else {
        window.addEventListener("resize", ()=> {
            SkillSheet.style.height = FooterCard.offsetHeight + PhoneCard.offsetHeight + "px";
        })
    }
  }); */

 /* else {

    fetch('../datas/data.json')
    .then(response => {
        if(!response.ok) {
            throw new Error('La requête a échoué');
        }
        return response.json();
    })
    .then(data => {
        datajson(data)
    })
    .catch(error => {
        console.error('Erreur lors de la récupération des fichiers JSON', error);
    });

    document.querySelector(".phone_card").classList.add("none")
    document.querySelector(".pc_card").classList.remove("none");
    document.querySelector(".skill_sheet").classList.add("none");
    let AvatarPicture = document.querySelector(".pc_card__image_container");
    let CardInfo = document.querySelector(".pc_card__info_container");
    let CardHeader = document.querySelector(".pc_card__header");

 /*   (document.querySelector(".website_container")).style.height = (document.querySelector(".pc_card__image_container")).offsetHeight + "px"; *-/
    let Main = document.querySelector("main")
    let PcCard = document.querySelector(".pc_card")
    let PageContainer = document.createElement("div")
    let Slider = document.createElement("div")
    let Title = document.querySelector(".website_container__title")
    let DescriptionContentChecker = "<p>Yo, je m'appelle Dorian&nbsp;!</p> <p>J'ai hâte de vous présentez tous mes différents projets&nbsp;!</p>"
    let SpeakingInterval
    let Speaking = false
    Slider.classList.add("slider")
    PageContainer.classList.add('page_content')
    let BodyContent = Main.innerHTML
    let PcDescription = document.querySelector(".description")
    let WebSlider = document.querySelector(".website_container")
    WebSlider.appendChild(Slider)
    let Wrappeur = document.createElement('div');
    Wrappeur.classList.add("wrappeur")
    Main.appendChild(PageContainer)
    PageContainer.appendChild(document.querySelector(".pc_card"))
    PageContainer.appendChild(Wrappeur)
    Wrappeur.appendChild(WebSlider)
    Wrappeur.appendChild(PcDescription)
    Slider.appendChild(Title)
    let Texte = document.querySelector(".text-container")
    let Level = document.querySelector(".pc-level")
    for(let i = 0; i <Websites.length; i++) {
        Slider.appendChild(Websites[i])
        Websites[i].style.height = (AvatarPicture.offsetHeight + CardHeader.offsetHeight) + "px";
    }
    PcDescription.style.height = (CardInfo.offsetHeight - 0.5) + "px";
    WebSlider.style.height = (AvatarPicture.offsetHeight + CardHeader.offsetHeight) + "px";
    Title.style.height = (AvatarPicture.offsetHeight + CardHeader.offsetHeight) + "px";
    console.log(AvatarPicture.offsetHeight + "+" + CardHeader.offsetHeight + " = " + (AvatarPicture.offsetHeight + CardHeader.offsetHeight))


    var scrollAnimation = gsap.timeline({
        scrollTrigger: {
            trigger: Main,
            scrub: true,
            end: Slider.offsetHeight,
            markers: true,
            pin: true,


            }
    })

    function labelToLevelUp(timeline, label) {
        let st = timeline.scrollTrigger;
        
        let val = (
          st.start +
          (Slider.offsetHeight - st.start) * (timeline.labels[label] / timeline.duration())
        )
        return val ;
      }
      let Avatar = document.querySelector(".pc-avatar")
      let FaceAnimationPC = [
        "assets/images/avatar_blinking.png",
        "assets/images/avatar_talking.png",
        "assets/images/avatar.png",
        "assets/images/avatar_talking_between.png"
    ];

      function blinking() {
        Avatar.setAttribute("src", FaceAnimationPC[0])
    setTimeout(()=> {
        Avatar.setAttribute("src", FaceAnimationPC[2])
    }, 250);
    }
    let BlinkingInterval = setInterval(blinking, 5000);
    function talking() {
        Avatar.setAttribute("src", FaceAnimationPC[1])
    setTimeout(()=> {
        Avatar.setAttribute("src", FaceAnimationPC[3])
    }, 200);
    setTimeout(()=> {
        Avatar.setAttribute("src", FaceAnimationPC[2])
    }, 400);
    }

    for (let i = 0; i < Websites.length ; i++) {
        scrollAnimation.to(".slider", {yPercent:  (-(100/(Websites.length+1))) * (i+1) })
        var LabelName = `Description${i}`
        scrollAnimation.addLabel(LabelName)
        if(i%2 == 0) {
            scrollAnimation.from(".filling", {scaleX: 0}, "<")
            .to(".filling", {scaleX:1}, "<")
        } else {
            scrollAnimation.from(".filling2", {scaleX: 0}, "<")
            .to(".filling2", {scaleX:1}, "<")
            .to(".filling2", {scaleX:0,duration: 0})
            .to(".filling", {scaleX:0,duration:0}, "<")
            var LevelUp = `Level${i}`
            scrollAnimation.addLabel(LevelUp)
        }
    }
    console.log(labelToLevelUp(scrollAnimation, "Description0"))
    let LevelValue = 0
    function datajson(jsonDatas) {
        window.addEventListener("scroll", () => {
            for (let i = 0; i < Websites.length ; i++) {
                if(window.scrollY > (labelToLevelUp(scrollAnimation, `Description${i}`)- (Websites[i].offsetHeight))) {
                    if( i !== Websites.length-1 && window.scrollY  < labelToLevelUp(scrollAnimation, `Description${i+1}`)) {
                        if(jsonDatas[jsonDatas.Projects[i]].length > 1) {
                            Texte.innerHTML = `<p>${jsonDatas[jsonDatas.Projects[i]][0]}</p><p>${jsonDatas[jsonDatas.Projects[i]][1]}</p>`
                        } else {
                            Texte.innerHTML = `<p>${jsonDatas[jsonDatas.Projects[i]][0]}</p>`
                        }
                    } else if( i == Websites.length-1) {
                        if(jsonDatas[jsonDatas.Projects[i]].length > 1) {
                            Texte.innerHTML = `<p>${jsonDatas[jsonDatas.Projects[Websites.length-1]][0]}</p><p>${jsonDatas[jsonDatas.Projects[Websites.length-1]][1]}</p>`
                        } else {
                            Texte.innerHTML = `<p>${jsonDatas[jsonDatas.Projects[Websites.length-1]][0]}</p>`
                        }
                    }
                } else if(window.scrollY < (labelToLevelUp(scrollAnimation, `Description${0}`) - (Websites[0].offsetHeight))) {
                    Texte.innerHTML = "<p>Yo, je m'appelle Dorian&nbsp;!</p> <p>J'ai hâte de vous présentez tous mes différents projets&nbsp;!</p>" 
    
                }
                for (let i = 0; i < Websites.length; i++) {
                    const levelLabel = labelToLevelUp(scrollAnimation, `Level${i}`);
                    
                    if (window.scrollY > levelLabel) {
                        if (LevelValue < i) {
                            Level.innerHTML = Number(Level.innerHTML) + 1;
                            LevelValue = i;
                            console.log(i);
                        }
                    } else if (window.scrollY < levelLabel) {
                        if (LevelValue > i) {
                            Level.innerHTML = Number(Level.innerHTML) - 1;
                            LevelValue = i;
                            console.log(i);
                        }
                    }
                }
            } 

            if(DescriptionContentChecker !== Texte.innerHTML && Speaking == false) {
                Speaking = true
                DescriptionContentChecker = Texte.innerHTML
                clearInterval(BlinkingInterval)
                SpeakingInterval = setInterval(talking, 300)
                setTimeout(() => {
                    clearInterval(SpeakingInterval)
                    BlinkingInterval = setInterval(blinking, 5000);
                    Speaking = false
                    DescriptionContentChecker = Texte.innerHTML //Pour éviter qu'il se remette à parler une deuxième fois si on fait un aller/retour
                }, 5000);
            }
    
        })
    }






    

}

/* Lorsque l'on change la taille de l'écran
mediaQuery.addListener((mediaQueryList) => {
    if (mediaQueryList.matches) {
      document.querySelector(".phone_card").classList.remove("none")
      document.querySelector(".pc_card").classList.add("none");
      document.querySelector(".skill_sheet").classList.remove("none");
    } else {
      document.querySelector(".phone_card").classList.add("none")
      document.querySelector(".pc_card").classList.remove("none")
      document.querySelector(".skill_sheet").classList.add("none");
      (document.querySelector(".website_container")).style.height = (document.querySelector(".pc_card__image_container")).offsetHeight + "px";
        let PageContainer = document.createElement('div')
        Body.appendchild(PageContainer)
      window.addEventListener("scroll", ()=>{

      })
    }
  });
  */